@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


body, div, p, h6, h5{
  font-family: 'Lato', sans-serif !important;
}

:root {
  --primary-color: #3C4096;
  --secondary-color: yellow;
}


.navbar{
  background-color: rgba(47, 46, 123, 0.9);
}

.nav-link{
  color: white !important;
  font-weight: 700;
}


.MuiStepIcon-root {
  color: #3C4096;
}

.MuiStepIcon-root.Mui-active {
  color: #FBD105 !important;
}


.MuiStepIcon-root.Mui-active.MuiStepIcon-text {
  color: white !important;
  font-family: 'Lato' !important;
}

.MuiStepConnector-line.MuiStepConnector-lineHorizontal{
  border-color: #3C4096 !important;
  border-width: 2px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-line.MuiStepConnector-lineHorizontal{
  border-color: #3C4096 !important;
}

.MuiStepLabel-label{
  color: #3C4096 !important;
}

.MuiStepIcon-root{
  color: #3C4096 !important;
}

.MuiStepLabel-label.Mui-active{
  color: #FBD105 !important;
  font-family: 'Lato' !important;
}

.MuiStepLabel-label.Mui-completed{
  color: #FBD105 !important;
  font-family: 'Lato' !important;
}

.MuiStepIcon-root.Mui-completed{
  color: #FBD105 !important;
  font-family: 'Lato' !important;
}

.text-right{
  text-align: right !important;
}

.button-yellow{
  background-color: #FBD105;
  width: 100px;
  color: #3C4096;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  height: 30px;
}


.primary-color{
  color: #3C4096;
}

.secondary-color{
  color: #FBD105;
}

.f-500{
  font-weight: 500;
}

.f-600{
  font-weight: 600;
}

.f-700{
  font-weight: 700;
}

.f-800{
  font-weight: 800;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: #3C4096 !important;
  min-height: auto !important;
  border: none !important;
  color: white;
  box-shadow: none!important;
  border-radius: 10px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
  border-radius: 10px !important;
  color: white;
  background-color: #3C4096;
  box-shadow: none!important;
  border: none !important;
}

.MuiAccordionSummary-root {
  box-shadow: none!important;
    background-color: #D8D9EA !important;
    border-radius: 10px !important;
  color: white;
  border: none !important;
}

.MuiAccordion-root:before{
  background-color: none !important;
}

.Mui-expanded > svg {
  background-color: #3C4096 !important;
  color: white !important;
}

.Mui-expanded > div > p {
  color: white !important;
}

.MuiAccordionDetails-root {
  background: #f9f9f9;
  color: var(--font-secondary);
}

.form-label{
  color: #3C409690 !important;
  font-size: 14px !important;
}

.form-control{
  border: 1px solid grey !important;
}

.MuiAccordion-root{
  box-shadow: none !important;
  background-color: transparent !important;
}
.MuiAccordionDetails-root{
  box-shadow: none !important;
}

.MuiPaper-root.MuiAccordion-root::before {
  background-color: #f7f7f7;
}

/* DROPDOWN STYLES */

.dropdown {
  padding: 12px;
  width: 95px;
  position: relative;
}

.dropdown-selection {
  background-color: #eaecf7;
  border-radius: 6px;
  padding: 12px 16px;
  cursor: pointer;
  position: relative;
}

.dropdown-selection::after {
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  border-right: 2px solid #0003;
  border-bottom: 2px solid #0003;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  top: 13px;
}

.dropdown-selection.visible::after {
  top: 18px;
  transform: rotate(-135deg);
}

.dropdown .list-holder {
  border-radius: 6px;
  background-color: white;
  position: absolute;
  width: 70px;
  z-index: 1000;
  top: 100%;
}

.dropdown .list-holder .dropdown-list {
  padding: 10px 16px;
  cursor: pointer;
  z-index: 1000;
}

.dropdown .list-holder .dropdown-list:hover {
  background-color: #0001;
  z-index: 1000;
}

.errors{
  font-size: 12px;
}

.delete-button{
  background-color: #E33252;
  width: 80px;
  border-radius: 5px;
  height: 30px;
  color: white;
}

.add-button{
  background-color: rgba(47, 46, 123, 0.9);
  width: 150px;
  border-radius: 5px;
  margin-left: 10px;
  height: 30px;
  color: white;
}



.MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color:yellow !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 4px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
