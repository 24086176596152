.header{
    text-align: center;
    font: normal normal 700 30px/29px Lato;
    letter-spacing: 0px;
    font-family: 'Lato', sans-serif;
    color: #282D2A;
    opacity: 1;
}

.boxBorder{
    background: white 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 0px;
}
.confirm_purchase{
    width: 100%;
    border-radius: 10px;
    height: 50px;
    border: none;
    font-size: 24px;
    font-weight: 700;
    outline: none;
    background-color: #FFD32B;
    color: #fff;
}

.requestOTP{
    width: 100%;
    height: 50px;
    background-color: #7BA345;
    border-radius: 10px;
    border: none;
    outline: none;
    color: white;
    margin-top: 1.5vh;
    font-size: 22px;
    font-weight: 700;
}

.boxBorder h4, h6{
    color: #5A727A;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}


.product_card{
    max-width: 460px;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #3C4096;
    border-radius: 10px;
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
}

.product_card_sub{
    width: 100%;
    padding: 15px;
    height: 100%;
    background-color: white;
    border-radius: 10px;
}

.product_card button{
    position: absolute;
    bottom: -15px;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color: #FBD105;
    color: #3C4096;
    height: 40px;
    width: 150px;
    font-weight: 700;
}

.product_card_button_selected{
    background-color: #3C4096 !important;
    color: white !important;
    border-width: 2px !important;
    border-color: white !important;
    border-style: solid !important;
}

.step_1_price_summary{
    background-color: #3C4096;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
}

.twoButtonContainer{
    width: 460px;
    height: auto;
    border: 1px solid #00000030;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.step_1_price_summary_sub{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 460px;
  
}

.headerContainer{
    background-image: url(../../assets//images//header-pattern.png);
    background-size: auto auto;
    background-repeat: no-repeat;
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
}

@media screen and (max-width: 768px) {
    .boxBorder{
        padding: 0px;
        background-color: #EAECF7;
        border: none;
        height: 100vh;
        margin-top: 0px !important;
    }

    .twoButtonContainer{
        width: 90%;
    }

    .form-container{
        background-color: #EAECF7 !important;
        padding: 0px !important;
    }

    .step_1_price_summary_sub{
        width: 300px;
    }
}
